<template>
	<div class="container">
		<Tabs ref="tabs" @selectedIndex="tabSelectedIndex" :tabType="1">
<!--			<Tab title="상담원 일괄 배정">-->
<!--				<div class="flex flex-wrap">-->
<!--					<div class="flex-initial w-5/12">-->
<!--						<h2>상담원 목록</h2>-->
<!--						<esp-dx-data-grid ref="batchAgent" :data-grid="dataGrid.batch.agent" />-->
<!--					</div>-->
<!--					<div class="flex-initial w-3/12">-->
<!--						<h2>배정 스킬 선택</h2>-->
<!--						<esp-dx-data-grid ref="batchSkl" :key="items.sklGroup" :data-grid="dataGrid.batch.skl" />-->
<!--					</div>-->
<!--					<div class="flex-initial w-4/12">-->
<!--						<h2>스킬 레벨 설정</h2>-->
<!--						<esp-dx-data-grid ref="batchPriority" :data-grid="dataGrid.batch.priority" />-->
<!--					</div>-->
<!--				</div>-->
<!--			</Tab>-->
			<Tab title="스킬 기준 관리">
				<div class="flex flex-wrap">
					<div class="flex-initial w-3/10">
						<h2>스킬 목록</h2>
						<esp-dx-data-grid ref="sklManagementSkl" :data-grid="dataGrid.sklManagement.skl" @row-click="onClickSklManagement"/>
					</div>
					<div class="flex-initial w-3/10">
						<h2>스킬 미보유 상담원</h2>
						<esp-dx-data-grid ref="sklManagementNotAssignedAgent" :data-grid="dataGrid.sklManagement.notAssignedAgent" />
					</div>
					<div class="flex-initial w-2/5">
						<h2>스킬 보유 상담원</h2>
						<esp-dx-data-grid ref="sklManagementAssignedAgent" :data-grid="dataGrid.sklManagement.assignedAgent" />
					</div>
				</div>
			</Tab>
			<Tab title="상담원 기준 관리">
				<div class="flex flex-wrap">
					<div class="flex-initial w-5/12">
						<h2>상담원 목록</h2>
						<esp-dx-data-grid ref="agentManagementAgent" :data-grid="dataGrid.agentManagement.agent" @row-click="onClickAgtManagement"/>
					</div>
					<div class="flex-initial w-3/12">
						<h2>미보유 스킬</h2>
						<esp-dx-data-grid ref="agentManagementNotAssignedSkl" :key="items.sklGroup" :data-grid="dataGrid.agentManagement.notAssignedSkl" />
					</div>
					<div class="flex-initial w-4/12">
						<h2>보유 스킬</h2>
						<esp-dx-data-grid ref="agentManagementAssignedSkl" :data-grid="dataGrid.agentManagement.assignedSkl" />
					</div>
				</div>
			</Tab>
		</Tabs>
		<section class="terms bottom-btn-box pad_t0">
			<div class="page-sub-box">
				<h2 class="hidden">일반 버튼</h2>
				<div class="bottom-btn-wrap">
					<DxButton text="스킬배정" :width="120" :height="40" class="default filled txt_S medium" type="default" @click="onSaving" />
					<DxButton text="취소" :width="120" :height="40" class="white filled txt_S medium" type="normal" @click="onCanceling" />
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import Tab from '@/components/common/tab.vue';
import Tabs from '@/components/common/tabs.vue';
import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';
import { DxButton } from 'devextreme-vue/button';
import { isSuccess } from '@/plugins/common-lib';

let vm = null;

export default {
	components: {
		Tabs,
		Tab,
    EspDxDataGrid,
		DxButton,
	},
	watch: {},
	data() {
		return {
			items: {
				siteTenant: null,
				sklGroup: null,
				skl: null,
				agent: null,
			},
			selected: {
				siteTenant: null,
				sklGroup:null,
				skl:null,
				agent:null,
			},
			dataGrid: {
				batch: {
					agent: [],
					skl: [],
					priority: [],
				},
				sklManagement: {
					skl: [],
					notAssignedAgent: [],
					assignedAgent: [],
				},
				agentManagement: {
					agent: [],
					notAssignedSkl: [],
					assignedSkl: [],
				},

				// 공통 선언부
				commonGrid: {
					allowColumnResizing: true,       //컬럼 사이즈 허용
					showBorders: true,               //border 유무
					showColumnHeaders: true,         //컬럼 헤더 유무
					showColumnLines: false,          //컬럼 세로선 유무
					showRowLines: true,              //컬럼 가로선 유무
					rowAlternationEnabled: false,
					wordWrapEnabled: false,          //줄바꿈 허용
					dataSource: [],
					customEvent: {
						selectionChanged: false,
						initNewRow: false,
						cellPrepared: false,
						rowPrepared: false,
						contentMenuPreparing: false,
						initialized: false,
						contentReady: true,
						optionChanged: false,
						exporting: false,
						rowInserting: false,
						rowInserted: false,
						rowUpdating: false,
						rowUpdated: false,
						rowRemoved: false,
						rowClick: true,
						saving: false
					},
					showActionButtons: {
						select: false,
						copy: false,
						delete: false,
						customButtons: []                               //pageData에서 vm을 찾지 못하여 페이지에서 직접 설정
					},
					grouping: {
						contextMenuEnabled: false,
						autoExpandAll: false,
						allowCollapsing: true,
						expandMode: 'rowClick'                          // rowClick or buttonClick
					},
					groupPanel: {
						visible: false
					},
					columnChooser: {
						enabled: false                                 // 컬럼 Chooser 버튼 사용유무
					},
					loadPanel: {
						enabled: true                                  // 로딩바 표시 유무
					},
					sorting: {
						mode: 'multiple'                                // single multiple
					},
					//scrolling: {                                      // 미사용시 주석처리
					//    mode: 'virtual'                           //스크롤 모드 : ['infinite', 'standard', 'virtual']
					//},
					remoteOperations: {                                 // 서버사이드 여부
						filtering: false,
						sorting: false,
						grouping: true,
						paging: true
					},
					paging: {                                           // scrolling 미사용시만 적용됨
						enabled: false,
						pageSize: 10,
						pageIndex: 0                                    // 시작페이지
					},
					pager: {
						visible: false,                                  //페이저 표시 여부
						showPageSizeSelector: false,                    //페이지 사이즈 선택버튼 표시 여부
						allowedPageSizes: [5, 10, 15, 20],              //페이지 사이즈 선택 박스
						displayMode: "compact",                         //표시 모드 : ['full', 'compact']
						showInfo: true,                                 //페이지 정보 표시 여부 : full인 경우만 사용 가능
						showNavigationButtons: true                     //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
					},
					filterRow: {
						visible: true,
						operationDescriptions: {
							contains: '포함'
						},
						value: [],
					},
					headerFilter: {
						visible: true
					},
					editing: {
						allowUpdating: false,                            // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
						allowDeleting: false,
						allowAdding: false,                              // 추가 버튼을 없애고 싶으면 false설정
						mode: 'batch',                                  //수정 모드: ['row', 'cell', 'batch']
						startEditAction: 'click',                       //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
						selectTextOnEditStart: true                    //셀 수정시 텍스트 전체 선택 여부
					},
					selecting: {
						mode: 'multiple',                               //행 단일/멀티 선택 타입 : ['single', 'multiple']
						selectAllMode: 'allPages',                      //행 선택 허용 범위 : ['allPages', 'page']
						showCheckBoxesMode: 'always'                   //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
					},
				},
				commonColumn : {
					alignment: 'center',                         // left center right
					visible: true,
					allowEditing: false,
					sortOrder: 'none',                          // acs desc none
					allowHeaderFiltering: false,
					fixed: false,                               // 컬럼 fix 시 사용
					fixedPosition: 'left',
				},
				commonButton: {
					widget: 'dxButton',
					options: {
						icon: '',
						width: 60,
						height: 30,
					},
					location: 'before',
				},
				commonSelectBox: {
					widget: 'dxSelectBox',
					options: {
						width: 200,
						height: 30,
						stylingMode: 'outlined',
						items: [],
						displayExpr: 'codeNm',
						valueExpr: 'codeValue',
					},
					location: 'before',
				}
			},
		};
	},
	computed: {
		/** @description : 공통코드에서 스킬레벨(priority) 리턴 */
		priority() {
			return this.$_getCode('ers_am_priority');
		},
	},
	methods: {
        onClickSklManagement(row){
            this.getAgtSklList(row.data.id);
        },
        onClickAgtManagement(row){
            console.log(row.data.agtid);
            this.getAgentSklList(row.data.agtid);
        },
		/** @description : 선택된 탭 인덱스 */
		tabSelectedIndex(index){
		},
		/** @description: 스킬배정 저장 로직 */
		async onSaving(e) {
            console.log("afafasdfasdfasfdasdfa");
		},
		/** @description: 취소 이벤트 */
		onCanceling(e) {
		},
		/** @description : data grid 초기화 */
		initDataGrid() {
			const commonGrid = this.dataGrid.commonGrid;
			const commonColumn = this.dataGrid.commonColumn;
			const commonButton = this.dataGrid.commonButton;
			const commonSelectBox = this.dataGrid.commonSelectBox;

			this.dataGrid.batch.agent = {
				...commonGrid,
				refName: 'batchAgent',
				columns: [
					{caption: '부서', dataField: 'agtteamNm', width: '40%'},
					{caption: '상담원', dataField: 'agtNm', width: '25%'},
					{caption: '상태', dataField: 'stateNm', width: '20%'},
					{caption: '스킬 개수', dataField: 'sklCnt', width: '15%'},
				],
				showActionButtons: {
				...commonGrid.showActionButtons,
						customButtons: [
						{
							...commonSelectBox,
							options: {
								...commonSelectBox.options,
								placeholder:'사이트/센터 선택',
								items: this.items.siteTenant,
								onValueChanged(e) { vm.setBatchSiteTenant(e) },
							},
						}
					]
				},
			};

			this.dataGrid.batch.skl = {
				...commonGrid,
				refName: 'batchSkl',
				columns: [
					{ ...commonColumn, caption: '배정 스킬 선택', dataField: 'sklNm', },
				],
			};

			this.dataGrid.batch.priority = {
				...commonGrid,
				refName: 'batchPriority',
				columns: [
					{ ...commonColumn, caption: '배정 스킬', dataField: 'sklPriorityNm', },
				],
				showActionButtons: {
					...commonGrid.showActionButtons,
					delete: true,
					customButtons: [
						{
							...commonButton,
							options: {
								...commonButton.options,
								text: '삭제',
								elementAttr: { class: 'btn_XS white light_filled trash' },
								onClick(e) { vm.deleteBatchSkl(e) },
							},
						},
						{
							...commonSelectBox,
							options: {
								...commonSelectBox.options,
								placeholder:'스킬레벨 일괄 적용',
								items: this.priority,
								onValueChanged(e) { vm.setBatchPriority(e) },
							},
						}
					],
				},

			};

			this.dataGrid.sklManagement.skl = {
				...commonGrid,
				refName: 'sklManagementSkl',
				columns: [
					{ ...commonColumn, caption: 'ID', dataField: 'id', },
					{ ...commonColumn, caption: '스킬', dataField: 'skl', },
					{ ...commonColumn, caption: '스킬명', dataField: 'sklNm', },
					// { ...commonColumn, caption: '스킬상담원', dataField: 'agentCnt', },
				],
                selecting: {
                    mode: 'multiple',                               //행 단일/멀티 선택 타입 : ['single', 'multiple']
                    selectAllMode: 'allPages',                      //행 선택 허용 범위 : ['allPages', 'page']
                    showCheckBoxesMode: 'onClick'                   //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
                },
                paging: { // scrolling 미사용시만 적용됨
                    enabled: true,
                    pageSize: 10,
                    pageIndex: 0 // 시작페이지
                },
			};
            this.getSklList();

			this.dataGrid.sklManagement.notAssignedAgent = {
				...commonGrid,
				refName: 'sklManagementNotAssignedAgent',
				columns: [
					{ ...commonColumn, caption: '상담원ID', dataField: 'agtId', width: '50%'},
					{ ...commonColumn, caption: '미보유상담원', dataField: 'agtNm', width: '50%'},
					// { ...commonColumn, caption: '상태', dataField: 'stateNm', width: '20%'},
				],
                paging: { // scrolling 미사용시만 적용됨
                    // enabled: true,
                    pageSize: 10,
                    pageIndex: 0 // 시작페이지
                },
				showActionButtons: {
					...commonGrid.showActionButtons,
					delete: true,
					customButtons: [
						{
							...commonButton,
							options: {
								...commonButton.options,
								text: '선택 배정',
								width: 100,
								elementAttr: { class: 'btn_XS default filled' },
								onClick(e) { vm.setSelectedSkl(e) },
							},
						},
						{
							...commonButton,
							options: {
								...commonButton.options,
								text: '모두 배정',
								width: 100,
								elementAttr: { class: 'btn_XS white light_filled' },
								onClick(e) { vm.setAllSkl(e) },
							},
						},
					],
				},
			};

			this.dataGrid.sklManagement.assignedAgent = {
				...commonGrid,
				refName: 'sklManagementAssignedAgent',
				columns: [
                    { ...commonColumn, caption: '배정상담원 ID', dataField: 'agtId', width: '33%'},
                    { ...commonColumn, caption: '배정상담원 이름', dataField: 'agtNm', width: '33%'},
					{ ...commonColumn, caption: '스킬레벨', dataField: 'priority', width: '33%'},
				],
                paging: { // scrolling 미사용시만 적용됨
                    enabled: true,
                    pageSize: 10,
                    pageIndex: 0 // 시작페이지
                },
				showActionButtons: {
					...commonGrid.showActionButtons,
					delete: true,
					customButtons: [
						{
							...commonButton,
							options: {
								...commonButton.options,
								text: '선택 미보유',
								width: 100,
								elementAttr: { class: 'btn_XS default filled' },
								onClick(e) { vm.removeSelectedSkl(e) },
							},
						},
						{
							...commonButton,
							options: {
								...commonButton.options,
								text: '모두 미보유',
								width: 100,
								elementAttr: { class: 'btn_XS white light_filled' },
								onClick(e) { vm.removeAllSkl(e) },
							},
						},
					],
				},
			};

			this.dataGrid.agentManagement.agent = {
				...commonGrid,
				refName: 'agentManagementAgent',
				columns: [
					{ ...commonColumn, caption: '부서', dataField: 'agtTeam.agtteamNm', },
					{ ...commonColumn, caption: '상담원 ID', dataField: 'agtid', },
					{ ...commonColumn, caption: '상담원 이름', dataField: 'agtNm', },
					// { ...commonColumn, caption: '스킬수', dataField: 'sklCnt', },
				],
                selecting: {
                    mode: 'multiple',                               //행 단일/멀티 선택 타입 : ['single', 'multiple']
                    selectAllMode: 'allPages',                      //행 선택 허용 범위 : ['allPages', 'page']
                    showCheckBoxesMode: 'onClick'                   //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
                },
                paging: { // scrolling 미사용시만 적용됨
                    enabled: true,
                    pageSize: 10,
                    pageIndex: 0 // 시작페이지
                },
			};

			this.dataGrid.agentManagement.notAssignedSkl = {
				...commonGrid,
				refName: 'agentManagementNotAssignedSkl',
				columns: [
					{ ...commonColumn, caption: '미보유스킬', dataField: 'sklNm', },
				],
                paging: { // scrolling 미사용시만 적용됨
                    enabled: true,
                    pageSize: 10,
                    pageIndex: 0 // 시작페이지
                },
			};

			this.dataGrid.agentManagement.assignedSkl = {
				...commonGrid,
				refName: 'agentManagementAssignedSkl',
				columns: [
					{ ...commonColumn, caption: '보유스킬', dataField: 'sklNm', },
				],
                paging: { // scrolling 미사용시만 적용됨
                    enabled: true,
                    pageSize: 10,
                    pageIndex: 0 // 시작페이지
                },
				// showActionButtons: {
				// 	...commonGrid.showActionButtons,
				// 	delete: true,
				// 	customButtons: [
				// 		{
				// 			...commonButton,
				// 			options: {
				// 				...commonButton.options,
				// 				text: '삭제',
				// 				elementAttr: { class: 'btn_XS white light_filled trash' },
				// 				onClick(e) { vm.deleteAgentSkl(e) },
				// 			},
				// 		},
				// 		{
				// 			...commonSelectBox,
				// 			options: {
				// 				...commonSelectBox.options,
				// 				placeholder:'스킬레벨 일괄 적용',
				// 				items: this.priority,
				// 				onValueChanged(e) { vm.setAgentPriority(e) },
				// 			},
				// 		}
				// 	],
				// },
			};
		},
		/** @description : sklGroup 필요한 dataGrid 별도 세팅  */
		setDataGridButtonWithSklGroup() {
			const commonGrid = this.dataGrid.commonGrid;
			const commonSelectBox = this.dataGrid.commonSelectBox;

			this.dataGrid.batch.skl.showActionButtons = {
				...commonGrid.showActionButtons,
				customButtons: [
					{
						...commonSelectBox,
						options: {
							...commonSelectBox.options,
							placeholder:'스킬 그룹 선택',
							items: this.items.sklGroup,
							onValueChanged(e) { vm.setBatchSklGroup(e) },
						},
					}
				]
			};

			this.dataGrid.agentManagement.notAssignedSkl.showActionButtons = {
				...commonGrid.showActionButtons,
				customButtons: [
					{
						...commonSelectBox,
						options: {
							...commonSelectBox.options,
							placeholder:'스킬 그룹 선택',
							items: this.items.sklGroup,
							onValueChanged(e) { vm.setAgentSklGroup(e) },
						},
					}
				]
			};
		},
        /** @description : 스킬 목록 조회 */
        async getSklList(){
            const payload = {
                actionname: "CC_SKL_LIST",
                loading: true,
            }

            const res = await this.CALL_CC_API(payload);
            if(isSuccess(res)) {
                this.dataGrid.sklManagement.skl.dataSource = res.data.data;
            }
        },
        async getAgtSklList(id){
            const payload = {
                actionname: "CC_SKL_AGT_LIST",
                data: {sklid : id, pageSize: 15},
                loading: true,
            }
            const res = await this.CALL_CC_API(payload);
            if(isSuccess(res)) {
                this.dataGrid.sklManagement.notAssignedAgent.dataSource = res.data.data.filter(item => item.flag === 'n');
                this.dataGrid.sklManagement.assignedAgent.dataSource = res.data.data.filter(item => item.flag === 'y');
            }
        },
		/** @description : 스킬그룹 목록 조회 */
		async setSklGroupList() {
			this.items.sklGroup = [];
			let payload = {
				actionname: 'SKL_GROUP_LIST_ALL',
				loading: true,
				useErrorPopup: true,
			}
			let res = await vm.CALL_CC_API(payload);
			if (isSuccess(res)) {
				const items = [];
				res.data.data.forEach((item) => {
					items.push({ codeNm: item.sklGroupNm, codeValue: item.id });
				});
				this.items.sklGroup = items;
				this.setDataGridButtonWithSklGroup();
			}
		},
		/** @description : 상담원 일괄배정 > 사이트/센터 선택 */
		setBatchSiteTenant(e) {
			console.log(e);
		},
		/** @description : 상담원 일괄배정 > 배정 스킬 선택 > 스킬그룹선택 */
		setBatchSklGroup(e) {
			console.log(e);
		},
		/** @description : 상담원 일괄배정 > 스킬레벨 삭제 */
		deleteBatchSkl(e) {
			console.log(e);
		},
		/** @description : 상담원 일괄배정 > 스킬레벨 일괄적용 */
		setBatchPriority(e) {
			this.setSklGroupList();
		},
		/** @description : 스킬 기준 관리 > 선택 배정 */
		setSelectedSkl(e) {
            const selectedRows = this.$refs.sklManagementNotAssignedAgent.selectedRowsData;
            this.dataGrid.sklManagement.assignedAgent.dataSource = [...selectedRows, ...this.dataGrid.sklManagement.assignedAgent.dataSource];
            this.$refs.agentManagementAssignedSkl.refreshData();
            const deletedRows = this.dataGrid.sklManagement.notAssignedAgent.dataSource.filter(agent =>
                !selectedRows.some(selectedRow => selectedRow.agtId === agent.agtId));
            this.dataGrid.sklManagement.notAssignedAgent.dataSource = deletedRows;
            this.$refs.agentManagementNotAssignedSkl.refreshData();
		},
		/** @description : 스킬 기준 관리 > 모두 배정 */
		setAllSkl(e) {
            this.dataGrid.sklManagement.assignedAgent.dataSource = [...this.dataGrid.sklManagement.notAssignedAgent.dataSource, ...this.dataGrid.sklManagement.assignedAgent.dataSource];
            this.$refs.agentManagementAssignedSkl.refreshData();
            this.dataGrid.sklManagement.notAssignedAgent.dataSource = [];
            this.$refs.agentManagementNotAssignedSkl.refreshData();
		},
		/** @description : 스킬 기준 관리 > 선택 미보유 */
		removeSelectedSkl(e) {
            const selectedRows = this.$refs.sklManagementAssignedAgent.selectedRowsData;
            this.dataGrid.sklManagement.notAssignedAgent.dataSource = [...selectedRows, ...this.dataGrid.sklManagement.notAssignedAgent.dataSource];
            this.$refs.agentManagementNotAssignedSkl.refreshData();
            const deletedRows = this.dataGrid.sklManagement.assignedAgent.dataSource.filter(agent =>
                !selectedRows.some(selectedRow => selectedRow.agtId === agent.agtId));
            this.dataGrid.sklManagement.assignedAgent.dataSource = deletedRows;
            this.$refs.agentManagementAssignedSkl.refreshData();
		},
		/** @description : 스킬 기준 관리 > 모두 미보유 */
		removeAllSkl(e) {
            this.dataGrid.sklManagement.notAssignedAgent.dataSource = [...this.dataGrid.sklManagement.assignedAgent.dataSource, ...this.dataGrid.sklManagement.notAssignedAgent.dataSource];
            this.$refs.agentManagementNotAssignedSkl.refreshData();
            this.dataGrid.sklManagement.assignedAgent.dataSource = [];
            this.$refs.agentManagementAssignedSkl.refreshData();
		},
		/** @description : 상담원 기준 관리 > 스킬그룹 선택 */
		setAgentSklGroup(e) {
			console.log(e);
		},
		/** @description : 상담원 기준 관리 > 스킬레벨 삭제 */
		deleteAgentSkl(e) {
			console.log(e);
		},
		/** @description : 상담원 기준 관리 > 스킬레벨 일괄적용 */
		setAgentPriority(e) {
			console.log(e);
		},
        async setAgentList(){
            const payload = {
                actionname: "CC_AGT_LIST",
                loading: true,
            }
            const res = await this.CALL_CC_API(payload);
            if(isSuccess(res)) {
                this.dataGrid.agentManagement.agent.dataSource = res.data.data;
                console.log(res);
                // this.dataGrid.sklManagement.notAssignedAgent.dataSource = res.data.data.filter(item => item.flag === 'n');
                // this.dataGrid.sklManagement.assignedAgent.dataSource = res.data.data.filter(item => item.flag === 'y');
            }
        },
        async getAgentSklList(id){
            const payload = {
                actionname: "CC_AGT_SKL_LIST",
                data: {agtId : id, pageSize: 15},
                loading: true,
            }
            const res = await this.CALL_CC_API(payload);
            if(isSuccess(res)) {
                this.dataGrid.agentManagement.notAssignedSkl.dataSource = res.data.data.filter(item => item.flag === 'n');
                this.dataGrid.agentManagement.assignedSkl.dataSource = res.data.data.filter(item => item.flag === 'y');
            }
        },
		/** @description : 라이프사이클 created 호출되는 메서드 */
		async createdData() {
			vm = this;
			this.initDataGrid();
			await this.setSklGroupList();
			// this.setSklList();
            await this.setAgentList();
		},
		/** @description : 라이프사이클 mounted 호출되는 메서드 */
		mountedData() {
		},
	}, // methods
	created() {
		this.createdData();
	},
	mounted() {
		this.mountedData();
	},
	updated() {},
	destroyed() {},
};
</script>

<style lang="scss" scoped>
.flex-initial {
	padding: 30px 20px 0 0;
}
.flex-initial h2 {
	font-size: 18px;
	color: #545454;
	font-weight: 500;
}
</style>
